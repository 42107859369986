import React, {useEffect, useState} from "react"
import styled from "styled-components";
import Layout from "../components/layout"
import SEO from "../components/seo"
import COLORS from "../styles/colors";
import {Link} from "gatsby";

const InnerPage = styled.div`
    max-width: 960px;
    margin: 0 auto;
    padding: 3rem 0;
`;

const StateTitle = styled.h1`
    text-align: center;
`;

const StyledLink = styled(Link)`
    border: 2px solid ${COLORS.primaryColor};
    padding: 0.5rem 1rem;
    margin: 1rem;
    text-decoration: none;
    color: ${COLORS.primaryColor};
    transition: all  0.5s;
    display: inline-block;

    &:hover {
        background-color: ${COLORS.primaryColor};
        color: #fff;
    }
`;

const Experience = ({location}) => {
    const [target, setTarget] = useState("");

    
    useEffect(() => {
        const {state} = location.state;
        setTarget(state);
    }, [location.state]);

    return (
        <Layout>
            <SEO title="Experience" />
            <InnerPage>
                <StateTitle>It looks like we're not in {target || "your state"} yet!</StateTitle>
                <p>As we work with new states, we will study your state standards and fully align our workshops to them.  Please check out our workshop offerings and let us create a customized experience just for you.</p>
                <div style={{textAlign: "center"}}><StyledLink to="/contact/">Contact Us</StyledLink></div>
            </InnerPage>
        </Layout>
    )
}


export default Experience


